<template>
  <div class="signup" v-if="!isProcessing">
    <v-img class="signup__logo" :src="require('@/assets/img/logo.svg')" contain />
    <h2 class="signup__title">入会申し込みの流れ</h2>
    <div class="signup__list">
      <div class="signup__list__item">
        <div class="signup__list__item__number" />
        <div class="signup__list__item__text">Googleアカウントでログイン</div>
      </div>
      <div class="signup__list__item">
        <div class="signup__list__item__number" />
        <div class="signup__list__item__text">あなたの情報の入力</div>
      </div>
      <div class="signup__list__item" v-if="!exemption">
        <div class="signup__list__item__number" />
        <div class="signup__list__item__text">クレジットカード情報の入力</div>
      </div>
      <div class="signup__list__item">
        <div class="signup__list__item__number" />
        <div class="signup__list__item__text">LDW限定Facebookグループへの参加申請</div>
      </div>
    </div>
    <v-btn class="signup__btn" depressed @click="signup()">Googleアカウントでログイン</v-btn>
    <warning class="signup__warning" :isExemption="!!exemption" />
  </div>
</template>

<script>
import Warning from '@/components/signup/warning.vue'

export default {
  name: 'signup',
  components: { Warning },
  async mounted () {
    // 例外情報を取得する
    if (this.email && !this.isGotExemption) await this.$store.dispatch('exemptions/getExemptionByEmail', this.email)

    // パラメータにemailが存在し、対応する例外情報がない場合はnot foundへ飛ばす
    if (this.email && !this.exemption) this.$router.push({ name: 'notfound' })

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {String} 例外ユーザーの場合のメールアドレス
     */
    email () {
      return this.$route.query.email
    },
    /**
     * @return {Object} 例外情報
     */
    exemption () {
      return this.$store.getters['exemptions/exemption']
    },
    /**
     * @return {Boolean} 例外情報取得済みかどうか
     */
    isGotExemption () {
      return this.$store.getters['exemptions/isGot']
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  },
  methods: {
    /**
     * アカウント作成
     */
    signup () {
      this.$store.commit('setSubmitting', true)

      // サインイン情報をセッションに保存
      // オブジェクトをそのまま保存すると文字列になるのでstringifyを使用
      sessionStorage.setItem('isSignupUser', true)
      if (this.exemption) sessionStorage.setItem('exemption', JSON.stringify(this.exemption))

      // Auth認証
      // 続きの処理はonAuthで実施
      this.$store.dispatch('auth/signin', 'google')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.signup {
  padding: $padding_height $padding_width 0;
  margin-top: $header_height;
  &__logo {
    height: 30px;
    margin: 30px auto 0;
  }
  &__title {
    margin: 10px 0 0;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }
  &__list {
    margin-top: 20px;
    counter-reset: number 0;
    &__item {
      display: flex;
      align-items: center;
      margin-top: 10px;
      font-size: 1.4rem;
      line-height: 2rem;
      &__number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        color: $white_color;
        background-color: $orange_color;
        border-radius: 50%;
        &::before {
          content: counter(number) " ";
          counter-increment: number 1;
        }
      }
      &__text {
        flex: 1;
        margin: 0 0 0 10px;
      }
    }
  }
  &__btn {
    display: block;
    margin: 40px auto 0;
    font-size: 1.2rem;
    color: $white_color;
    border-radius: 15px;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: 124px;
      max-width: 100%;
      height: 35px;
      padding: 0 20px;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $orange_color;
    }
    & ::v-deep .v-btn__content {
      display: block;
      min-width: 0;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: none;
      white-space: nowrap;
    }
  }
  &__warning {
    margin-top: 40px;
  }
}
</style>
